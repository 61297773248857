var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('full-overlay',{attrs:{"show":_vm.loading}}),_c('b-button-group',[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.add}},[_vm._v("Добавить")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.getList}},[_vm._v("Обновить")])],1),_c('b-table',{staticClass:"mt-3",attrs:{"items":_vm.list,"fields":_vm.fields,"striped":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(value.ru))]),_c('div',[_vm._v(_vm._s(value.en))])]}},{key:"cell(icon)",fn:function(ref){
var id = ref.item.id;
return [_c('img',{attrs:{"src":("" + (_vm.$store.state.api) + _vm.icon + "/" + id + ".svg?" + (Date.now())),"width":"45"}})]}},{key:"cell(settings)",fn:function(ref){
var id = ref.item.id;
return [_c('b-button-group',[_c('b-button',{on:{"click":function($event){return _vm.edit(id)}}},[_vm._v("Редактировать")]),(false)?_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.remove(id)}}},[_vm._v(" Удалить ")]):_vm._e()],1)]}}])}),_c('b-modal',{attrs:{"title":"Редактирование","hide-footer":"","size":"xl"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.send($event)}}},[_c('b-form-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Имя"}},[_c('b-form-input',{attrs:{"required":""},model:{value:(_vm.modal.name.ru),callback:function ($$v) {_vm.$set(_vm.modal.name, "ru", $$v)},expression:"modal.name.ru"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"[EN] Имя"}},[_c('b-form-input',{attrs:{"required":""},model:{value:(_vm.modal.name.en),callback:function ($$v) {_vm.$set(_vm.modal.name, "en", $$v)},expression:"modal.name.en"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Новая иконка"}},[_c('b-form-file',{attrs:{"accept":"image/svg+xml","required":_vm.modal.id == 0},model:{value:(_vm.modal.file),callback:function ($$v) {_vm.$set(_vm.modal, "file", $$v)},expression:"modal.file"}})],1)],1),_c('b-col',[(_vm.modal.id)?_c('b-form-group',{attrs:{"label":"Текущая иконка"}},[_c('img',{attrs:{"src":("" + (_vm.$store.state.api) + _vm.icon + "/" + (_vm.modal.id) + ".svg?" + (Date.now())),"width":"45"}})]):_vm._e()],1)],1),_c('div',{staticClass:"map"},[_c('img',{attrs:{"src":"/map.svg","alt":"","width":_vm.width,"height":_vm.height},on:{"click":_vm.setPoint}}),_c('div',{staticClass:"map__pointer",style:({
                        left: ((_vm.modal.position.left) + "%"),
                        top: ((_vm.modal.position.top) + "%"),
                    })})]),_c('b-button',{staticClass:"mt-1",attrs:{"type":"submit"}},[_vm._v("Сохранить")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }