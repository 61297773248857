<template>
    <div>
        <full-overlay :show="loading" />
        <b-button-group>
            <b-button variant="success" @click="add">Добавить</b-button>
            <b-button variant="primary" @click="getList">Обновить</b-button>
        </b-button-group>
        <b-table :items="list" :fields="fields" striped class="mt-3">
            <template #cell(name)="{ value }">
                <div>{{ value.ru }}</div>
                <div>{{ value.en }}</div>
            </template>
            <template #cell(icon)="{ item: { id } }">
                <img
                    :src="`${$store.state.api}${icon}/${id}.svg?${Date.now()}`"
                    width="45"
                />
            </template>
            <template #cell(settings)="{ item: { id } }">
                <b-button-group>
                    <b-button @click="edit(id)">Редактировать</b-button>
                    <b-button @click="remove(id)" variant="danger" v-if="false">
                        Удалить
                    </b-button>
                </b-button-group>
            </template>
        </b-table>
        <b-modal title="Редактирование" v-model="show" hide-footer size="xl">
            <b-form @submit.prevent="send">
                <b-form-row>
                    <b-col>
                        <b-form-group label="Имя">
                            <b-form-input v-model="modal.name.ru" required />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="[EN] Имя">
                            <b-form-input v-model="modal.name.en" required />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Новая иконка">
                            <b-form-file
                                v-model="modal.file"
                                accept="image/svg+xml"
                                :required="modal.id == 0"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Текущая иконка" v-if="modal.id">
                            <img
                                :src="`${$store.state.api}${icon}/${
                                    modal.id
                                }.svg?${Date.now()}`"
                                width="45"
                            />
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <div class="map">
                    <img
                        src="/map.svg"
                        alt=""
                        @click="setPoint"
                        :width="width"
                        :height="height"
                    />
                    <div
                        class="map__pointer"
                        :style="{
                            left: `${modal.position.left}%`,
                            top: `${modal.position.top}%`,
                        }"
                    ></div>
                </div>
                <b-button type="submit" class="mt-1">Сохранить</b-button>
            </b-form>
        </b-modal>
    </div>
</template> 

<style scoped lang="scss">
.map {
    position: relative;
    img {
        margin: 0 auto;
    }
    &__pointer {
        width: 20px;
        height: 20px;
        background: tomato;
        border-radius: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    }
}
</style>

<script>
import FullOverlay from "@c/FullOverlay.vue";
export default {
    name: "Country",
    components: {
        FullOverlay,
    },
    data() {
        return {
            loading: false,
            list: [],
            fields: [
                {
                    key: "id",
                    label: "#",
                },
                {
                    key: "name",
                    label: "Имя",
                },
                {
                    key: "icon",
                    label: "Иконка",
                },
                {
                    key: "settings",
                    label: "",
                },
            ],
            show: false,
            modal: this.getModal(),
            width: 1100,
            height: 638,
            icon: "",
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        async edit(id) {
            this.loading = true;
            const { one } = await this.$fetchAdmin(`/country/${id}`).then((r) =>
                r.json()
            );
            this.modal = Object.assign(this.getModal(), one);
            this.show = true;
            this.loading = false;
        },
        async remove(id) {
            const st = await this.$bvModal.msgBoxConfirm(
                "Подтвердите удаление"
            );
            if (!st) {
                return false;
            }
            this.loading = true;
            await this.$fetchAdmin(`/country/${id}`, {
                method: "DELETE",
            }).then((r) => r.json());
            this.loading = false;
            this.getList();
        },
        setPoint({ offsetY, offsetX }) {
            this.modal.position.top = Math.round((offsetY / this.height) * 100);
            this.modal.position.left = Math.round((offsetX / this.width) * 100);
        },
        async send() {
            this.loading = true;
            const body = new FormData();
            body.append("id", this.modal.id);
            body.append("name[ru]", this.modal.name.ru);
            body.append("name[en]", this.modal.name.en);
            body.append("position[left]", this.modal.position.left);
            body.append("position[top]", this.modal.position.top);
            if (this.modal.file) {
                body.append("icon", this.modal.file);
            }
            await this.$fetchAdmin("/country/save", {
                method: "POST",
                body,
            }).then((r) => r.json());
            this.loading = false;
            this.show = false;
            this.getList();
        },
        add() {
            this.modal = this.getModal();
            this.show = true;
        },
        async getList() {
            this.loading = true;
            const { list, icon } = await this.$fetchAdmin(
                "/country"
            ).then((r) => r.json());
            this.list = list;
            this.icon = icon;
            this.loading = false;
        },
        getModal: () => ({
            id: 0,
            name: {
                ru: "",
                en: "",
            },
            position: {
                left: 0,
                top: 0,
            },
            file: null,
        }),
    },
};
</script>